@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap'); 



body {
  margin: 0;
  padding: 0;
  background-color: #333947;
  color: #fff;
  font-family: 'Arial', sans-serif;
  overflow-x: hidden; 
  user-select: none; 
  transition: filter 0.3s ease; 
  animation: fadeInBlur 1s ease forwards; 
}

.loading-container {
  display: flex; 
  flex-direction: column; 
  align-items: center; 
  justify-content: center; 
  height: 100vh; 
  animation: fadeInBlur 1s ease forwards;
}



/* General scrollbar style for cross-browser support */
* {
  scrollbar-width: thin;
  border-radius: 10px;
  scrollbar-color: #292929c4 transparent; /* Scroll thumb on transparent track */
}

/* Webkit (Chrome, Safari) specific scrollbar styling */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent; /* Transparent track */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #333;
  border-radius: 10px;
  
}

::-webkit-scrollbar-thumb:hover {
  background-color: #777777;
}



.loading-logo {
  width: 100px; 
  height: auto; 
  animation: spin 2s linear infinite;
}


.loading-text {
  margin-top: 20px;
  font-size: 24px; 
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@keyframes fadeInBlur {
  0% {
    opacity: 0; 
    filter: blur(10px);
  }
  100% {
    opacity: 1; 
    filter: blur(0); 
  }
}


body.blur {
  filter: blur(5px); 
}


.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh; 
  box-sizing: border-box;
  overflow-y: auto; 
  overflow-x: hidden; 
}

.App-header {
  background: linear-gradient(90deg, #404552, #6b6e81); /* Gradient background for a modern look */
  width: 100%;
  padding: 20px 0; /* Increased padding for a fuller appearance */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; 
  top: 0;
  left: 0;
  z-index: 1000;
  overflow-x: hidden; 
}
.App-selection-bar {
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  align-items: center;
  height: 70px; /* Increased height */
  padding: 0 30px; /* More side padding */
  background: linear-gradient(90deg, #3e4c6c, #4a5579); /* Background gradient */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow */
}

.Selection-logo {
  max-height: 60px; /* Adjust logo height */
  transition: transform 0.3s ease;
}

.App-nav {
  display: flex;
  align-items: center;
  margin-left: 20px; 
}

.App-nav-link {
  color: #EAEAEA;
  text-decoration: none;
  padding: 15px 20px; /* Increased padding */
  border-radius: 5px;
  margin: 0 10px; /* Space between links */
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s; /* Smooth transition */
}

.App-nav-link:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Lighter background on hover */
  color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Shadow on hover */
}

.App-nav-link i {
  margin-right: 5px; /* Space between icon and text */
}



.App-main {
  padding: 20px;
  background-color: #333947;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 90px; 
  box-sizing: border-box;
  flex: 1; 
  overflow-y: auto; 
  overflow-x: hidden; 
}

#dextswap-aggregator-widget {
  width: 100%; /* Rende l'iframe responsivo, adattandolo alla larghezza del contenitore */
  max-width: 800px; /* Limita la larghezza massima per una migliore visualizzazione su schermi più grandi */
  height: 500px; /* Imposta l'altezza per garantire che tutto il contenuto sia visibile */
  border: none; /* Rimuove il bordo predefinito dell'iframe */
  border-radius: 15px; /* Aggiunge angoli arrotondati per un aspetto moderno */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Aggiunge un'ombra leggera per far risaltare l'iframe */
  margin: 20px auto; /* Centra l'iframe all'interno del contenitore */
  margin-top: 50px;
  display: block; /* Assicura che l'iframe sia trattato come un blocco e rispetti la proprietà `margin` */
  overflow-y: none;
}



.Project-overview {
  text-align: center;
  margin-bottom: -70px;
  margin-top: 100px; 
}
.Project-overviewh5 {
  text-align: center;
  margin-bottom: 0px;
  margin-top: 0px; 
  font-size: 1.8rem;
}

.Project-overview h1 {
  font-size: 2.5rem; 
  margin-bottom: 10px; 
  color: #c5cce8;
  max-width: 100%; 
  overflow: hidden; 
  white-space: nowrap;
  text-overflow: ellipsis; 
  text-align: center; 
}

.Project-overview h3 {
  font-size: 2.5rem;
  margin-bottom: 100px; 
  color: #ffffff;
  margin-top: -75px
}

.Project-overview p {
  font-size: 1.25rem;
}

.Social-links-container {
  display: flex;
  flex-direction: column;
  gap: 15px; 
  align-items: center;
  margin-top: 10px;
 
}

.Social-links-row {
  display: flex;
  gap: 20px;
  margin-top: -25px
}

.Selection-link {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  padding: 5px;
  transition: transform 0.3s ease;
}

.Selection-link img {
  height: 60px;
  width: auto;
}

.Selection-link:hover {
  transform: scale(1.1); 
}

.Project-details {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 30px;
}

.Detail-card {
  background: #1F2A38;
  padding: 20px;
  margin-top: 40px;
  border-radius: 20px;
  color: #EAEAEA;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
  width: 300px;
  text-align: center;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
}

.Detail-card h2 {
  background-color: #2c3e50a2;
  color: #F0F0F0;
  padding: 20px;
  border-radius: 20px 20px 0 0;
  font-size: 1.8rem;
  margin: 0;
  position: relative;
  z-index: 1;
  width: 100%;
  box-sizing: border-box;
}

.Detail-card .top-bar {
  height: 60px;
  background: linear-gradient(to bottom, #1F2A38, #2C3E50);
  border-radius: 20px 20px 0 0;
  width: 100%;
  position: absolute;
  top: -10px;
  left: 0;
  z-index: -1;
}

.Detail-card p {
  font-size: 1.4rem;
  word-wrap: break-word;
  color: #EAEAEA;
  line-height: 1.6;
}

.Detail-card-smart-contract p {
  color: #C0C0C0;
  user-select: text;
}

/* Hover effect */
.Detail-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
}

.Meme-section {
  margin: 40px 0;
  text-align: center;
}

.Meme-title {
  font-size: 2rem;
  margin-bottom: 15px;
  color: #838bab;
}

.Meme-link {
  display: inline-block;
  margin-bottom: 30px;
  padding: 5px 10px;
  background-color: #1F2A38;
  color: #EAEAEA;
  border-radius: 10px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.Meme-link:hover {
  background-color: #2c3e50;
}

.Meme-gallery-title {
  font-size: 1.5rem;
  margin: 20px 0;
  color: #838bab;
}

.Meme-gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr); 
  gap: 15px; 
  justify-items: center;
}

.Meme-image {
  width: 200px;
  height: 200px;
  border-radius: 10px;
  object-fit: cover; 
  transition: transform 0.2s;
  cursor: pointer; 
}




.Meme-image:hover {
  transform: scale(1.05); 
}


.Button-container {
  text-align: center;
  margin-top: 40px;

}

.Meme-button {
  display: inline-block; 
  padding: 15px 30px; 
  background-color: #939dc3;
  color: white;
  text-decoration: none; 
  border-radius: 50px; 
  font-size: 18px; 
  font-weight: bold;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3); 
  transition: all 0.3s ease; 
  position: relative; 
  overflow: hidden; 
}

.Meme-button::before {
  content: '';
  position: absolute; 
  top: 50%; 
  left: 50%; 
  width: 300%; 
  height: 300%; 
  background: rgba(255, 255, 255, 0.2); 
  border-radius: 50%; 
  transform: translate(-50%, -50%) scale(0); 
  transition: transform 0.3s ease; 
  z-index: 0; 
}

.Meme-button:hover::before {
  transform: translate(-50%, -50%) scale(1); 
}

.Meme-button:hover {
  background-color: #5f646c; 
  transform: translateY(-2px); 
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.5);
}

.Meme-button:active {
  transform: translateY(2px); 
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); 
}


.Modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow: auto; /* Permette lo scroll interno se l'immagine è molto grande */
}

.Modal-image {
  max-width: 80%;
  max-height: 80%;
  border-radius: 10px;
  margin: auto; /* Mantiene l'immagine centrata nel contenitore */
}

.Banner {
  background-color: #282c34;
  color: white;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  width: 100vw; 
  padding: 10px 0; 
}


.Banner p {
  display: inline-block; 
  padding: 0; 
  margin: 0 10px; 
  font-family: 'Fjalla One', sans-serif;
  font-size: 48px; 
  line-height: 1.5; 
  animation: scroll 10s linear infinite;
  white-space: nowrap; 
}


.Bannerh2 {
  background-color: #3a8b37;
  color: white;
  overflow: hidden;
  white-space: nowrap;
  width: 100vw;
  margin-top: 50px;
  margin-bottom: -40px;
  padding: 10px 0;
}

.scroll-content {
  display: flex;
  animation: scroll 10s linear infinite;
}

.Bannerh2 span {
  display: inline-block;
  margin: 0 10px;
  font-family: 'Fjalla One', sans-serif;
  font-size: 54px;
  line-height: 1.5;
  white-space: nowrap;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}




.Story {
  
  margin-bottom: 20px;
  font-size: 1.5rem;
  line-height: 1.4; 
  text-align: center;
  max-width: 600px; 
  margin-left: auto; 
  margin-right: auto; 
  font-family: 'Impact', sans-serif;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7); 
}

.main-content {
  min-height: calc(100vh - 100px); 
  padding-bottom: 20px; 
}

.footer {
  background-color: #282c34; 
  color: white;
  padding: 20px; 
  text-align: center;
  position: relative; 
  bottom: 0; 
  width: 110%; 
  box-sizing: border-box;
  margin-bottom: -20px;
}

.Community-info {
  margin: 0; 
  opacity: 1; 
}

.App-disclaimer {
  margin-top: 10px; 
  opacity: 0.5; 
}


.footer p {
  margin: 5px 0; 
  font-size: 14px;
}

.footer a {
  color: #61dafb;
  text-decoration: none; 
}

.footer a:hover {
  text-decoration: underline;
}


#dextswap-aggregator-widget {
  width: 100%; /* Imposta la larghezza al 100% del contenitore */
  height: 500px; /* Altezza fissa o relativa, a seconda delle esigenze */
  max-width: 400px; /* Imposta una larghezza massima per schermi più grandi */
  border: none; /* Rimuovi il bordo */
  margin-top: 40px; /* Margine superiore per separarlo da altri elementi */
}

.meme-layout {
  display: flex;
  gap: 20px;
  padding: 20px;
  justify-content: center;
}

/* Sezione principale a sinistra (l'immagine grande con i bottoni sotto) */
.meme-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.selection-rectangle {
  position: absolute;
  border: 2px dashed rgba(0, 0, 255, 0.5);
  background: rgba(0, 0, 255, 0.2);
  pointer-events: none; /* Prevent interactions */
}


.meme-image-container {
  width: 100%;
  aspect-ratio: 1; /* Assicura che sia un quadrato */
  margin-bottom: 20px;
  border-radius: 15px;
  border: 2px dashed #1e2125;
  box-shadow: 0 0 20px rgba(20, 21, 22, 0.5);
  position: relative;
  overflow: hidden;
  
}

.meme-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
  pointer-events: none;
}
.overlay-wrapper {
  position: absolute;
  display: inline-block;
  transform: translate(var(--x), var(--y)) scale(var(--scale)) rotate(var(--rotation)) scaleX(var(--flip)); /* Applica trasformazioni direttamente qui */
  top: 0;
  left: 0;
  cursor: move;
  transition: box-shadow 0.3s ease;
}


.overlay-wrapper.selected {
  box-shadow: 0 0 15px 5px #222222;
}

.overlay-image {
  pointer-events: none;
  border-radius: 15px;
  width: 100%;
  height: 100%;
  transform-origin: center;
  transition: transform 0.2s; /* Aggiungi una transizione fluida */
  transform: rotate(var(--rotation, 0deg)); /* Usa una variabile per la rotazione */
  cursor: pointer;
}

.overlay-wrapper.scaled .overlay-image {
  transform: rotate(var(--rotation, 0deg)) scale(var(--scale, 1)) scaleX(var(--flip, 1));
  transition: transform 0.2s ease; /* Transizione fluida */
}


.rotation-handle,
.scale-handle {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.6);
  border: 1px solid #888;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.rotation-handle {
  right: 10px;
  top: -30px;
}

.scale-handle {
  bottom: -30px;
  right: -30px;
}

.controls {
  position: absolute;
 margin-bottom: 40px;
  top: -60px; /* Puoi mantenere questo valore */
  left: 50%; /* Posiziona il contenitore al centro */
  transform: translateX(-50%); /* Sposta il contenitore a sinistra della metà della sua larghezza */
  display: flex;
  gap: 8px;
  background: #2e3442cc;
  padding: 8px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}


.control-button {
  background: #000000d0;
  border: none;
  color: white;
  font-size: 16px;
  border-radius: 20%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.control-button .icon {
  width: 16px; /* Imposta la larghezza dell'icona */
  height: 16px; /* Imposta l'altezza dell'icona */
  border-radius: 0;
}

.control-button:hover {
  background: #666;
  transform: scale(1.1);
}

.control-button:active {
  transform: scale(0.95);
}

.meme-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.button {
  flex: 1;
  height: 96px; /* Uguale altezza degli elementi a destra */
  margin: 0 5px;
  font-size: 16px;
  border: solid 3px #425b77 ;
  border-radius: 15px;
  background-color: #1c2d41;
  color: white;
 
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.button:hover {
  background-color: #0c121a;
  transform: translateY(-2px);
}

.button:active {
  transform: translateY(0);
}

.button-icon {
  max-width: 40px; /* Grandezza massima delle immagini */
  max-height: 40px;
  object-fit: contain; /* Mantiene il rapporto d'aspetto dell'immagine */
}

.button-icon:hover {
  opacity: 0.8; /* Effetto hover sull'immagine */
}


.item {
  background-color: rgba(240, 240, 240, 0.315);
  border: 3px solid #818181;
  height: 100px; /* Fixed height for all items */
  aspect-ratio: 1; /* Ensure square shape */
  display: flex;
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  border-radius: 15px;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.item:hover {
  transform: scale(1.05);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}





.meme-items::after {
  content: '';
  flex: auto; /* Ensures additional space is taken up */
}

.meme-items {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Quattro colonne */
  gap: 5px;
  max-width: 400px; /* Imposta una larghezza massima per tutta l'area della griglia */
  max-height: 570px; /* Limita l'altezza massima dell'area della griglia */
  overflow-y: auto; /* Abilita lo scroll verticale */
  padding-right: 10px; /* Spazio per la barra di scorrimento */
  
  box-sizing: border-box; /* Assicura che il padding non ecceda la dimensione massima */
}

/* Assicurati che gli elementi siano quadrati */
.item {
  aspect-ratio: 1; /* Mantiene il quadrato */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(240, 240, 240, 0.315);
  border: 3px solid #818181;
  border-radius: 15px;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  max-width: 80px; /* Riduci la larghezza massima di ciascun item */
}

.item-image {
  width: 60%;
  height: 50%;
  object-fit: cover;
  border-radius: 0px;
  border-radius: 10px;
}

.item-imageh3 {
  width: 90%;
  height: 35%;
  object-fit: cover;
  border-radius: 0px;
  border-radius: 10px;
}


.item-imageh2 {
  width: 100%;
  height: 50%;
  object-fit: cover;
 
}



.category-buttons {
  flex-direction: column;
  align-items: center;
}


.category-buttons button {
  background-color: #252c33; /* Colore di sfondo */
  color: #2e2e2e; /* Testo bianco */
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 0 5px; /* Spaziatura orizzontale tra i pulsanti */
}

.category-buttons button:hover {
  background-color: #14191f; /* Colore di sfondo al passaggio del mouse */
}

.meme-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  justify-content: center;
}

.button-icon {
  width: 24px;
  height: 24px;
}




.scale-slider {
  width: 100px;
  margin-top: 10px;
  accent-color: #999999bb;
}

.rotation-slider {
  width: 100px;
  margin-top: 10px;
  accent-color: #999999bb;
}

.resize-handle {
  position: absolute;
  width: 10px; /* Width of handle */
  height: 10px; /* Height of handle */
  background-color: #333; /* Color of handle */
  border-radius: 5px; /* Rounded corners */
  cursor: nwse-resize; /* Cursor type for resizing */
}

.top-left {
  top: -5px; /* Positioning of top left handle */
  left: -5px;
}

.top-right {
  top: -5px; /* Positioning of top right handle */
  right: -5px;
}

.bottom-left {
  bottom: -5px; /* Positioning of bottom left handle */
  left: -5px;
}

.bottom-right {
  bottom: -5px; /* Positioning of bottom right handle */
  right: -5px;
}



.Roadmap {
  text-align: center;
  margin: 2em 0;
}

.Meme-gallery-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1em;
}

.cardh2 {
  width: 750px; /* Larghezza della card uguale alla larghezza della GIF */
  height: auto;
  background: #07182E;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 20px;
  padding: 10px; /* Aggiunto padding per dare spazio interno */
  margin: 0 auto; /* Per centrare la card */
}

.cardh2::before {
  content: '';
  position: absolute;
  top: -15%;  /* Sposta verso l'alto per coprire completamente il perimetro */
  left: -15%; /* Sposta a sinistra per coprire il perimetro */
  width: 120%; /* Estende oltre il perimetro della card */
  height: 120%; /* Estende oltre il perimetro della card */
  background-image: linear-gradient(180deg, #696E82, #2F3543);
  border-radius: 20px; /* Applica il border-radius alla pseudo-immagine */
  animation: rotBGimg 9s linear infinite;
  transition: all 1s linear;
  z-index: 0; /* Assicurati che stia dietro il contenuto della card */
}

@keyframes rotBGimg {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cardh2::after {
  content: '';
  position: absolute;
  background: #07182E;
  inset: 5px;
  border-radius: 20px;
  z-index: 1; /* Metti l'after sopra il before ma sotto la gif */
}

.Roadmap-gif {
  width: 100%;
  height: auto;
  border-radius: 15px;
  z-index: 2; /* Assicura che la GIF sia sopra tutti */
  margin: 5px; /* Aggiunto un piccolo margin per distanziare la GIF dai bordi */
}







.DexTools-chart-section {
  display: flex;
  flex-direction: column;
  align-items: center; 
}

.DexTools-chart {
  width: 100%; 
  max-width: 1200px;
  height: 500px;  
  margin-top: 40px; 
  border: none;  
}



.Source {
  font-size: 1.2rem;
  color: #c0c0c0;
  text-align: center; 
  margin-top: 10px;
  font-family: 'Arial', sans-serif; 
}

.Source a {
  color: #c0c0c0;
  text-decoration: none; 
}

.Source a:hover {
  text-decoration: underline; 
}





@media (min-width: 1024px) {
  .App {
    overflow: hidden; 
  }

  

  
}



/* Ottimizzazione per schermi da 300px a 360px */
@media (max-width: 400px) {
  
  .Meme-link {
    width: 90%; 
    padding: 10px;  /* Ridotto il padding per maggiore spazio */
    font-size: 18px;  /* Font più piccolo per adattarsi allo schermo */
  }
  
  .Meme-gallery {
    grid-template-columns: 1fr; /* Un'unica colonna per dispositivi più piccoli */
    padding: 0 5px;
  }

  .Meme-image {
    max-height: 120px;  /* Ridotto per adattarsi meglio */
    max-width: 120px;
  }

  .Meme-gallery-title {
    font-size: 1.4rem;  /* Font più piccolo */
  }

  .Meme-title {
    font-size: 1.3rem;  /* Font più piccolo */
  }

  .Social-links-row {
    gap: 8px;  /* Spazio ridotto */
  } 

  .App-nav {
    flex-direction: row; /* Mantiene i link affiancati */
    justify-content: flex-start; 
    margin-left: 5px; /* Margine ridotto */
    overflow-x: auto; /* Scorrimento orizzontale se necessario */
  }

  .App-nav-link {
    margin: 0 5px; /* Spazio tra i link */
    width: auto; /* Mantiene la larghezza automatica */
    text-align: center; 
    font-size: 0.9rem; /* Font più piccolo */
  }

  .Selection-link img {
    height: 30px; /* Immagine più piccola */
  }
  
  .Detail-card {
    width: 90%; 
    padding: 10px; /* Ridotto per maggiore spazio */
  }

  .Detail-card h2 {
    font-size: 1.1rem; /* Font più piccolo */
    padding: 10px;
  }

  .Detail-card p {
    font-size: 0.9rem; 
    line-height: 1.3; 
  }

  .Detail-card .top-bar {
    height: 40px; /* Ridotto per adattarsi meglio */
    top: -5px; 
  }

  .DexTools-chart {
    width: 280px;  
    height: 350px;  
    margin-top: 20px;
    border: none; 
  }


  .meme-layout {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
  }

  
  .Dextswap-aggregator {
    width: 280px; 
    height: 250px;
    margin-top: 30px; 
    border: none; 
  }
  
  .footer {
    width: 100%; /* Regola la larghezza per il footer */
  }
  .meme-layout {
    padding: 10px; /* Reduce padding on mobile */
  }

  .meme-items {
    display: grid; /* Definisci il contenitore come Grid */
    grid-template-columns: repeat(2, 1fr); /* Due colonne */
    justify-items: center; /* Centra gli elementi all'interno di ogni cella */
}

.item {
  flex: 0 0 45%; /* Due elementi per riga su mobile */
  max-width: 100%; /* Può rimanere, ma controlla che il contenitore non sia più largo */
  box-sizing: border-box; /* Assicurati che il padding e il margine non aumentino la larghezza */
}


  
  .cardh2 {
    width: 95%; /* Ancora più stretta per schermi molto piccoli */
    padding: 5px;
    border-radius: 10px; /* Riduci ancora il border-radius */
  }

  .cardh2::before {
    top: -25%; /* Ancora più regolazioni per coprire i bordi */
    left: -25%;
    width: 150%; /* Espandi di più per coprire completamente i bordi */
    height: 150%;
  }

  .Meme-gallery-title {
    font-size: 1.5rem; /* Riduci la dimensione del titolo su schermi piccoli */
  }
  
}


.meme-image[data-id="2"] {
  position: absolute;
  left: 60%; 
  top: 10;
  transform: rotate(-35deg);
  width: 40px;
  height: auto;
}





@media (max-width: 600px) {


  .App-selection-bar {
    justify-content: center;
    padding: 0 10px; 
  }
  
  .App-nav {
    margin-left: 0; 
  }
  
  .App-nav-link {
    padding: 10px 15px; 
  }

  .Meme-link {
    width: 90%; 
    padding: 15px; 
    font-size: 20px; 
}
  .Meme-gallery {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 5px;
  }

  .Meme-image {
    max-height: 150px;
    max-width: 150px;
  }

  .Meme-gallery-title {
    font-size: 1.5rem;
  }
  .Meme-link {
    font-size: 1.2rem;
  
  }
  .Meme-title {
    font-size: 1.5rem;
  }

  .Social-links-row {
    
    
    gap: 10px; 
    
  } 
  .App-nav {
    justify-content: flex-start; /* Align nav items to the left */
    margin-left: 10; /* Remove left margin for better alignment */
    overflow-x: auto;
  }

  .App-nav-link {
    margin: 5px 0; 
    width: 100%; 
    overflow-x: none;
    text-align: center;

  }

  #dextswap-aggregator-widget {
    height: 400px;
    width: 100%; 
    max-width: 100%; 
    margin-top: 30px;
  }

  .Selection-link img {
    height: 40px;
  }
  

  .meme-layout {
    padding: 10px; 
  }

  .meme-items {
    display: grid; 
    grid-template-columns: repeat(2, 1fr);
    justify-items: center; 
}

.item {
  flex: 0 0 45%; 
  max-width: 100%;
  box-sizing: border-box;
}
  
  .meme-layout {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    
  }
  .meme-main {
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; 
  }
  

  .Detail-card {
    width: 60%; 
    padding: 15px; 
  }

  .Detail-card h2 {
    font-size: 1.2rem;
    padding: 15px;
  }

  .Detail-card p {
    font-size: 1.0rem; 
    line-height: 1.4; 
  }

  .Detail-card .top-bar {
    height: 50px; 
    top: -8px; 
  }

  .DexTools-chart {
    width: 300px;  
    height: 400px;  
    margin-top: 30px;
    border: none; 
  }
  
  .Dextswap-aggregator {
    width: 90%; 
    height: auto; 
    max-width: 100%; 
  }
  .footer {
    width: 115%; 
    
  }


  .cardh2 {
    width: 90%; 
    padding: 5px; 
    border-radius: 15px; 
  }

  .cardh2::before {
    top: -20%; 
    left: -20%;
    width: 140%; 
    height: 140%;
  }


}

@media (min-width: 601px) and (max-width: 1023px) {
  .Meme-gallery {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 10px;
  }

  .Meme-image {
    max-height: 110px;
  }

  .Meme-gallery-title, .Meme-link {
    font-size: 1.5rem;
  }

  .Meme-title {
    font-size: 2rem;
  }

  .Social-links-row {
    gap: 10px;
  }

  .Selection-link img {
    height: 50px;
  }

  .DexTools-chart {
    width: 550px;  
    height: 400px;  
    margin-top: 40px;  
    border: none;  
  }
  
  .Dextswap-aggregator {
    width: 400px;  
    height: 400px;  
    margin-top: 40px;  
    border: none;  
  }

  

}

@media (min-width: 1024px) {
  .Meme-gallery {
    grid-template-columns: repeat(4, 1fr);
    padding: 0 20px;
  }
  
  
  .Meme-image {
    max-height: 150px;
  }

  .Meme-gallery-title, .Meme-link {
    font-size: 2rem;
  }

  .Meme-title {
    font-size: 2.5rem;
  }

  .Social-links-row {
    gap: 15px;
  }

  .Selection-link img {
    height: 60px;
  }

  .DexTools-chart {
    width: 1150px;  
    height: 400px;  
    margin-top: 40px;  
    border: none;  
  }
  
  .Dextswap-aggregator {
    width: 400px;  
    height: 400px;  
    margin-top: 40px;  
    border: none;  
  }


}
